import React, { useState , useEffect ,useContext } from 'react';
import './Navbar.css';
import vtplogo from '../assets/vtplogo.webp';
import axios from "axios";
import { ThanksContext } from "../App";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { ip, setThanksState } = useContext(ThanksContext);  //Handeling Form Logic //data
  const navigate = useNavigate();


  useEffect(() => {
    setEnquiryData((prevData) => ({ ...prevData, ip }));
  }, [ip]);

  //Handeling Navbar toggling icon
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //handeling click event of nav-btn
  const handleNavClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // popup form 
  const [formPopup, setFormpopup] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [transitionClass, setTransitionClass] = useState("");
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    origin: "",
    area: "form_popup",
    ip: "",
    domain: "vtpnaturescapes.com",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      // Allow only letters and spaces for the 'name' field
      value = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === "phone") {
      // Allow only numbers for the 'phone' field and limit length to 12 characters
      value = value.replace(/[^0-9]/g, '').substring(0, 10);
    }
  
    setEnquiryData({ ...enquiryData, [name]: value });
  };

  const closePopup = (e) => {
    e.stopPropagation();
    setIsExiting(true);
    setTimeout(() => {
      setFormpopup(false);
      setIsExiting(false);
    }, 300); // Duration of the transition in milliseconds
  };

  useEffect(() => {
    if (formPopup) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setTransitionClass("popupEffect");
      }, 10); // Delay adding the class
    } else {
      setTransitionClass(""); // Remove the class immediately
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [formPopup]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(enquiryData);
    try {
      const fetchData = async () => {
        const jsonData = JSON.stringify(enquiryData);
        await axios
          .post(
            "https://www.crm.brickfolio.in/api/leads/add_raw_lead",
            jsonData,
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            setThanksState(true);
            navigate("/thanks");
          });
      };
      fetchData();
        // console.log(enquiryData);
    } catch (error) {
      console.log(error.message);
    }
  };








  return (
    <div>
    <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
      <div className="logo">
        <img src={vtplogo} alt="Logo" className="logo" />
      </div>
      <div className={`mobile-menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleNavClick}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#bestprice">Best Price</a></li>
        <li><a href="#amenities">Amenities</a></li>
        <li><a href="#gallery">Gallery</a></li>
        <li><a href="#floorplans">Floor Plan</a></li>
        <li><a href="#location">Location</a></li>
        <li><a href="#enqury">Contact Us</a></li>
        <li className="enquiry-button-mobile"><button className="buttonnv"  onClick={() => setFormpopup(!formPopup)} >Enquiry Now</button></li>
      </ul>
      <button class="buttonnv desktop" onClick={() => setFormpopup(!formPopup)} >Enquiry Now</button>
    </nav>
    {formPopup && (
        <div
          className={`popupContainer ${isExiting ? "exit" : ""}`}
          onClick={closePopup}
        >
          <div
            className={`popup ${transitionClass}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="popup-img-cont">
              <img src={vtplogo} alt="" />
            </div>
            <div className="popup-inp-cont">
              <h2>Register to Enquiry</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  pattern="[A-Za-z\s]+"
                  title="Please enter a valid name (only letters and spaces allowed)."
                  value={enquiryData.name} 
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  minLength="10"
                  maxLength="12"
                  pattern="[0-9]+"
                  title="Please enter a valid number (only numbers allowed)."
                  value={enquiryData.phone}
                  onChange={handleChange}
                  required
                />
                <div className="popup-submit-btn-cont">
                  <button type="submit" className="submit-btn">
                  Enquiry Now
                  </button>
                </div>
              </form>
              <button className="close-btn" onClick={closePopup}>
                X
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;

// enquiry-button desktop