import React from "react";
import "./Disclaimer.css";
// import { Link } from "react-router-dom";

const Disclaimer = () => {
  return (
    <div className="disclaimer-cont">
      <div>
        <p className="disclaimer">
        <a href="https://brickfolio.in/privacy" target="_blank" rel="noopener noreferrer"> Privacy & Policy</a> : The content on this page is intended for informational purposes 
        only and does not constitute an offer to purchase any services.
         Prices mentioned are subject to change without prior notice,
          and the availability of properties may vary. All images are for 
          representation purposes only. Please note that this is not the 
          official website and is managed by our online marketing agency, 
          Pact Partners. By engaging with this website, you consent to receive 
          updates via the contact information provided. We urge all viewers to 
          exercise due diligence on <a href="https://maharerait.mahaonline.gov.in/" target="_blank" rel="noopener noreferrer">MAHARERA</a> website before making any decisions.
           Our RERA Agent Number is A52100018143, the Project MahaRera Number is
           Coming Soon.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
