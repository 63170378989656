import React, { useContext, useEffect, useState } from "react";
import "./About.css";
import { useLocation, useNavigate } from "react-router";
import { ThanksContext } from "../App";
import vtpab from "../assets/vtpab.webp";
import axios from "axios";
import vtplogo from '../assets/vtplogo.webp';

const About = () => {
  const { ip, setThanksState } = useContext(ThanksContext);
  const navigate = useNavigate();
  //Handeling Form Logic
  //data
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    origin: "",
    area: "form_popup",
    ip: "",
    domain: "vtpnaturescapes.com",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });

 //Setting ip address
 useEffect(() => {
  setEnquiryData((prevData) => ({ ...prevData, ip }));
}, [ip]);

const handleChange = (e) => {
  const name = e.target.name;
  let value = e.target.value;

  if (name === "name") {
    // Allow only letters and spaces for the 'name' field
    value = value.replace(/[^A-Za-z\s]/g, '');
  } else if (name === "phone") {
    // Allow only numbers for the 'phone' field and limit length to 12 characters
    value = value.replace(/[^0-9]/g, '').substring(0, 10);
  }

  setEnquiryData({ ...enquiryData, [name]: value });
};
const handleSubmit = (e) => {
  e.preventDefault();
  // console.log(enquiryData);
  try {
    const fetchData = async () => {
      const jsonData = JSON.stringify(enquiryData);
      await axios
        .post(
          "https://www.crm.brickfolio.in/api/leads/add_raw_lead",
          jsonData,
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          setThanksState(true);
          navigate("/thanks");
        });
    };
    fetchData();
      // console.log(enquiryData);
  } catch (error) {
    console.log(error.message);
  }
};
     //utm data
     const location = useLocation();

     useEffect(() => {
       const searchParams = new URLSearchParams(location.search);
       if (searchParams.get("utm_source")) {
         setEnquiryData((prevData) => ({
           ...prevData,
           utm_source: searchParams.get("utm_source"),
           utm_medium: searchParams.get("utm_medium"),
           utm_campaign: searchParams.get("utm_campaign"),
           utm_term: searchParams.get("utm_term"),
           utm_content: searchParams.get("utm_content"),
         }));
       }
     }, [location]);

     const [formPopup, setFormpopup] = useState(false);
     const [isExiting, setIsExiting] = useState(false);
     const [transitionClass, setTransitionClass] = useState("");

     useEffect(() => {
      if (formPopup) {
        document.body.style.overflow = "hidden";
        setTimeout(() => {
          setTransitionClass("popupEffect");
        }, 10); // Delay adding the class
      } else {
        setTransitionClass(""); // Remove the class immediately
        document.body.style.overflow = "auto";
      }
  
      return () => {
        document.body.style.overflow = "auto";
      };
    }, [formPopup]);
  
    const closePopup = (e) => {
      e.stopPropagation();
      setIsExiting(true);
      setTimeout(() => {
        setFormpopup(false);
        setIsExiting(false);
      }, 300); // Duration of the transition in milliseconds
    };
    //************** */
  



















  return (
    <div className="about-cont" id="about">
      <div className="about-info-img-container">
        <div className="about-card">
          <h1>About VTP NatureScape</h1>
          <p>
            A Home Amidst Verdant Greenery, yet Close to all the Creature Comforts of a modern lifestyle is Exactly what you ever wanted! Located In West Bavdhan Surrounded by amidst Forest covered on 3 Sides, Codename Naturescape Not Only Enjoys Beautiful views but also enjoy a quietly tucked away location. Minutes Away From the bustling hub of chandani chow and kothrud, Project Offers Luxury with Comfort.
            
          </p>
          <button
               className="buttonn"  onClick={() => setFormpopup(!formPopup)}>
                Know More
              </button>
        </div>
        <img src={vtpab} alt="" className="about-main-img" />
      </div>
      {formPopup && (
        <div className={`popupContainer ${isExiting ? "exit" : ""}`} onClick={()=> setFormpopup(!formPopup)}>
          <div className={`popup ${transitionClass}`} onClick={(e)=> e.stopPropagation()}>
            <div className="popup-img-cont">
              <img src={vtplogo} alt="" />
             </div>
            <div className="popup-inp-cont">
              <h2>Register to Know More</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  pattern="[A-Za-z\s]+"
                  title="Please enter a valid name (only letters and spaces allowed)."
                  value={enquiryData.name} 
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  minLength="10"
                  maxLength="12"
                  pattern="[0-9]+"
                  title="Please enter a valid number (only numbers allowed)."
                  value={enquiryData.phone}
                  onChange={handleChange}
                  required
                />
                <div className="popup-submit-btn-cont">
                  <button type="submit" className="submit-btn">
                  Know More
                  </button>
                </div>
              </form>
              <button className="close-btn" onClick={closePopup}>
                X
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
