import React from "react";
import map from "../assets/map.webp";
import './Location.css'
 import record from '../assets/record.webp'
// import tree from '../assets/tree.png'

const About = () => {
  return (
    <>
      
      <div className="container" id="location">
      <h1 className="head">VTP NatureScape Location</h1>
        <section className="about">
        <div className="about-image">
            <img src={map} alt="About Us" />
          </div>
          <div className="about-content">
            <p> <img src={record} alt="Mountain Icon" className="icon"/>
            Access Bavdhan East or Kothrud within a few minutes
            drive (2.5 kms distance) from the project. </p>
          <p> <img src={record} alt="Mountain Icon" className="icon"/>The Mumbai-Pune-Bangalore Highway (NH 48) is just
            5 mins. away and offers easy access to all parts of Pune
            city especially along the highway like - Bavdhan East,
             Kothrud.baner. Pashan. Aunon. Sus. Warle Wakad ete.
            </p>
          <p><img src={record} alt="Mountain Icon" className="icon"/>The proposed Pune Ring Road being planned by the
             PMRDA will connect Bavdhan.</p>
              <p><img src={record} alt="Mountain Icon" className="icon"/>Hinjewadi IT Park, Lohia - Jain IT Park, Oracle, Calsoft,
           Fundtech, Prabhavee Tech Park, Nano Space IT Park, and
           Pune IT Park.</p>
              <p><img src={record} alt="Mountain Icon" className="icon"/>The entire corporate hub of Baner-Balewadi is buzzing
            with new development and iob opportunities.</p>
              <p><img src={record} alt="Mountain Icon" className="icon"/>The newly opened Phoenix Millennium Mall at Wakad.</p>
              <p><img src={record} alt="Mountain Icon" className="icon"/>The metro will connect Chandani Chowk in the West to
              Ramwadi in East.</p>
           
          </div>
         
         
        </section>
      </div>
    </>
  );
};

export default About;


{/* <img src={mountain} alt="Mountain Icon" className="icon"/>
<img src={tree} alt="Mountain Icon" className="icon"/> */}