import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import garden from '../assets/garden.webp';
import parking from '../assets/parking.webp';
import party from '../assets/party.webp';
import sports from '../assets/sports.webp';
import pich from '../assets/pich.webp';
import swimmin from '../assets/swimmin.webp';

import "./Gallery.css";

const Gallery = () => {
  const isMobileView = window.innerWidth <= 768; // Check if it's a mobile view

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: isMobileView ? 1 : 3, // Show 1 slide on mobile, 3 on larger screens
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="con" id="gallery">
      <h1 className="head">VTP NatureScape Gallery</h1>
      <Slider {...settings}>
        <div>
          <img className="imghi" src={garden} alt="1" />
        </div>
        <div>
          <img className="imghi" src={parking} alt="2" />
        </div>
        <div>
          <img className="imghi" src={sports} alt="3" />
        </div>
        <div>
          <img className="imghi" src={pich} alt="4" />
        </div>
        <div>
          <img className="imghi" src={party} alt="5" />
        </div>
        <div>
          <img className="imghi" src={swimmin} alt="6" />
        </div>
      </Slider>
    </div>
  );
};

export default Gallery;
