import React, { useState, useEffect } from "react";
import './Home.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Hero3 from "../assets/Hero3.webp";
import Hero4 from "../assets/Hero4.webp";
import vtpmo from "../assets/vtpmo.webp";

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 600;

  return (
    <div className="hero-container" id='home'>
      {isMobile ? (
        <Carousel
          infiniteLoop
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
        >
         <div className="carousel-item">
<img src={vtpmo} alt="homeImg1" />

</div>
{/* <div className="carousel-item">
<img src={temp2} alt="homeImg2" />
</div> */}
          {/* Add more carousel items for mobile view if needed */}
        </Carousel>
      ) : (
        <Carousel
          infiniteLoop
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
        >
         <div className="carousel-item">
            <img src={Hero3} alt="homeImg1" />
          </div>
          <div className="carousel-item">
            <img src={Hero4} alt="homeImg2" />
          </div>
          {/* Add desktop carousel items here */}
        </Carousel>
      )}

      {/* {isMobile && (
        <div className="mobile-hero">
          
        </div>
      )} */}
    </div>
  );
};

export default Home;


