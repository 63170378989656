import React, { useState, useEffect, useContext } from "react";
import cont from "../assets/cont.webp"
import ambg from "../assets/ambg.webp"
import "./Enquiry.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Disclaimer from "./Disclaimer";
import { ThanksContext } from "../App";


const Enquiry = () => {

  const { ip, setThanksState } = useContext(ThanksContext);
  const navigate = useNavigate();
  //Handeling Form Logic
  //data
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    email: "",
    phone: "",
    origin: "",
    area: "footer",
    ip: "",
    domain: "vtpnaturescapes.com",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });

  //Setting ip address
  useEffect(() => {
    setEnquiryData((prevData) => ({ ...prevData, ip }));
  }, [ip]);

   //utm data
   const location = useLocation();

   useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("utm_source")) {
      setEnquiryData((prevData) => ({
        ...prevData,
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_term: searchParams.get("utm_term"),
        utm_content: searchParams.get("utm_content"),
      }));
    }
  }, [location]);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      // Allow only letters and spaces for the 'name' field
      value = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === "phone") {
      // Allow only numbers for the 'phone' field and limit length to 12 characters
      value = value.replace(/[^0-9]/g, '').substring(0, 10);
    }
    setEnquiryData({ ...enquiryData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(queryParam.get("utm_source"));
    try {
      const fetchData = async () => {
        const jsonData = JSON.stringify(enquiryData);
        await axios
          .post(
            "https://www.crm.brickfolio.in/api/leads/add_raw_lead",
            jsonData,
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            setThanksState(true);
            navigate("/thanks");
          });
      };
      fetchData();
      // console.log(enquiryData)
    } catch (error) {
      console.log(error.message);
    }
  };











  
 return (
      <>
        <div className="footer-parent-cont" id="enqury">
          <div className="footer-logo-cont">
            {/* <img src={godrejlogo} alt=""/> */}
          </div>
          <div className="enquiry-form-cont">
            <div className="enquiry-leftside">
            <picture>
              <source srcSet={cont} type="image/jpg" />
              <source srcSet={ambg} type="image/jpg" />
              <img
                src={cont}
                alt="amenImg1"
                className="w-100"
              />
            </picture>
            </div>
            <div className="enquiry-rightside">
              <h1 className="drop-enquiry">DROP AN ENQUIRY</h1>
              <form onSubmit={handleSubmit}>
              <div>
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    pattern="[A-Za-z\s]+"
                    title="Please enter a valid name (only letters and spaces allowed)."
                    onChange={handleChange}
                    value={enquiryData.name} 
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Mobile Number"
                    maxLength="12"
                    minLength="10"
                    pattern="[0-9]+"
                    title="Please enter a valid number (only numbers allowed)."
                    onChange={handleChange}
                    value={enquiryData.phone}
                    required
                  />
                </div>
                <div className="enquiry-submit-btn-cont">
                  <button className="btn enquiry-submit-btn" type="submit">
                    <span>Schedule a Site Visit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Disclaimer />
      </>
    );
  };

export default Enquiry;
