import React from "react";
import "./Amenities.css";
import dance from '../assets/dance.webp'
import swim from '../assets/swim.webp'
import gym from '../assets/gym.webp'
import ludo from '../assets/ludo.webp'
import sportss from '../assets/sportss.webp'
import parkarea from '../assets/parkarea.webp'
import cricket from '../assets/cricket.webp'
import parkk from '../assets/parkk.webp'

const Amenities = () => {
  return (
  <div id="amenities">
    <h2 className="align fw-bold py-4 px-2 text-light text-shadow">
    VTP NatureScape <span className="main-text-clr2">Amenities</span>
  </h2>
    <div className="pad-height amenities-cont" id="amenities">
      
      <div className="amen-all">
        
        <div>
       
        </div>
        <div className="amen-logos">
          <div className="display d-flex justify-content-evenly g-20 align-items-start row-1">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
                <picture>
                    <source srcSet={dance} type="image/webp"/>
                    <source srcSet={dance} type="image/webp"/>
                    <img src={dance} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
                Party Laws
              </p>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={parkk} type="image/webp"/>
                    <source srcSet={parkk} type="image/webp"/>
                    <img src={parkk} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
              Garden area
              </p>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={gym} type="image/webp"/>
                    <source srcSet={gym} type="image/webp"/>
                    <img src={gym} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
               Indoor Gym
              </p>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={ludo} type="image/webp"/>
                    <source srcSet={ludo} type="image/webp"/>
                    <img src={ludo} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
              Club House
              </p>
            </div>
          </div>

          <div className="display d-flex justify-content-evenly mt-5 g-20 align-items-start row-2">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={cricket} type="image/webp"/>
                    <source srcSet={cricket} type="image/webp"/>
                    <img src={cricket} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
              Outdoor Games
              </p>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={parkarea} type="image/webp"/>
                    <source srcSet={parkarea} type="image/webp"/>
                    <img src={parkarea} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
              Parking Area
              </p>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={swim} type="image/webp"/>
                    <source srcSet={swim} type="image/webp"/>
                    <img src={swim} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
             Swmming Pool
              </p>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="amen-logo-cont">
              <picture>
                    <source srcSet={sportss} type="image/webp"/>
                    <source srcSet={sportss} type="image/webp"/>
                    <img src={sportss} alt="" />
                </picture>
              </div>
              <p className="text-light fw-bold">
              Indoor Games
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Amenities;
